<template>
<div>
    <div v-if="page.image">
      <b-carousel fade>
        <b-carousel-slide v-for="(image, index) in images" 
          :key="index"
          :img-src="image">
        </b-carousel-slide>
      </b-carousel>
    </div>

    <b-container class="my-5">
      <h1 class="text-center">{{ $t('general.mediaLibTitle') }}</h1>
      <div class="text-center my-5" v-html="page.content"></div>
      <div class="row">
        <div class="col-sm-4 mb-3">
            <router-link :to="{ name: 'AllVideosPublic', params : { lang: $root.lang.shortName } }">
              <div class="mlib text-center">
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27 52.1954V19.8145L52 36L27 52.1954Z" fill="#005B70"/>
                  <circle cx="36" cy="36" r="32.5" stroke="#9ADCDA" stroke-width="7"/>
                </svg>
                <h2 class="text-center mt-4">{{ $t('general.videoLib') }}</h2>
              </div>
            </router-link>
        </div>

        <div class="col-sm-4 mb-3">
          <router-link :to="{ name: 'AllAudioPublic', params : { lang: $root.lang.shortName } }">
            <div class="mlib text-center">
                <svg width="54" height="72" viewBox="0 0 54 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.6105 34.1055C46.6105 45.4739 36.9853 53.4318 26.5263 53.4318C16.0674 53.4318 6.4421 45.4739 6.4421 34.1055H0C0 47.0276 10.3074 57.7139 22.7368 59.5707V72.0002H30.3158V59.5707C42.7453 57.7518 53.0526 47.0655 53.0526 34.1055H46.6105Z" fill="#9ADCDA"/>
                <rect x="15" width="23" height="45" rx="11.5" fill="#005B70"/>
                </svg>
                <h2 class="text-center mt-4">{{ $t('general.audioLib') }}</h2>
            </div>
          </router-link>
        </div>

        <div class="col-sm-4 mb-3">
          <router-link :to="{ name: 'AllImagesPublic', params : { lang: $root.lang.shortName } }">
            <div class="mlib text-center">
                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M64 8V64H8V8H64ZM64 0H8C3.6 0 0 3.6 0 8V64C0 68.4 3.6 72 8 72H64C68.4 72 72 68.4 72 64V8C72 3.6 68.4 0 64 0Z" fill="#9ADCDA"/>
                <path d="M24 40.5L12 56H60L44.5 35.5L32.5 51L24 40.5Z" fill="#005B70"/>
                </svg>
                <h2 class="text-center mt-4">{{ $t('general.photoLib') }}</h2>
            </div>
          </router-link>
        </div>
      </div>
    </b-container>
    <b-container class="shlajsna" style="padding: 80px 0;">
      <b-row>
        <b-col cols="12" class="text-center">
          <div class="logo d-inline-block"></div>
        </b-col>
      </b-row>
    </b-container>

</div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
export default {
  components: {BCarousel, BCarouselSlide},
  data() {
    return {
      page: {},
      url: "media"
    }
  },
  async created() {
    await this.$http.get("/api/page/get/" + this.lang.shortName + "/" + this.url).then(r => {
      this.page = r.data;
      this.images = JSON.parse(this.page.image);
      this.shuffle(this.images);
    }).catch(error => {
      console.log(error);
      if (error.response.status === 404) {
        this.page.title = "404"
        this.page.content = this.$t('page.not-found');
      }
    })
  },
  mounted() {
    window.$(".logo").ripples({ resolution: 50, perturbance: 0.030});
  },

  methods: {
    shuffle: function(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
  },
  
  metaInfo: function() {
    return {
      title: this.$t('general.full-name')
    }
  },

  computed: {
    lang: function() {
      return this.$root.lang
    }
  },
  watch: {
     lang : async function(newLang, oldLang) {
      await this.$http.get("/api/page/get/" + newLang.shortName + "/" + this.url).then(r => {
        this.page = r.data;
      }).catch(e => {
        this.handleError(e);
      })
    }
  }
}
</script>

<style>
.carousel-item > img {
  height: 450px;
  object-fit: cover !important;
  background-position: 50% 50%;
}
</style>
